import React from "react"
import Layout from "../components/Layout/Layout"
import LayoutStyles from "../components/LayoutStyles/LayoutStyles"
import StyledBackgroundImageFull from "../components/BackgroundImageFull/BackgroundImageFull"
import SectionTransition from "../components/SectionTransition/SectionTransition"
import ProfilePic from "../components/ProfilePic/ProfilePic"
import Placard from "../components/Placard/Placard"
import CalloutAction from "../components/CalloutAction/CalloutAction"
import { Helmet } from "react-helmet"
import ScrollNudge from "../components/ScrollNudge/ScrollNudge"
import { Link } from 'gatsby'

export default function About() {
  return(
    <div>
      <Layout>
        <Helmet>
          <title>About | Alan Henson - Freelance Full-Stack Engineer</title>
        </Helmet>
        <LayoutStyles>
        <StyledBackgroundImageFull imgName={'jellyfish'}/>
        <div className={'above-fold-container'} >
            <div className={'gradient-billboard'}>
              <div style={{marginTop: '5vh'}} className={'typography-main'}>About</div>
            </div>
            <SectionTransition to={'#5F6260'} static>
              <ScrollNudge/>
            </SectionTransition>
            </div>
          <div style={{backgroundColor: '#5F6260', padding: '2vh 2vw'}}>
            <ProfilePic/>
            <div className={'text-list text-list-left'}>
              <ul>
                <li>Hi, I'm Alan!</li>
                <li>I'm a freelance software engineer/architect in the U.S.</li>
                <li>I work mostly on the web, though I love learning about technologies and paradigms in other spaces.</li>
              </ul>
            </div>
              <div className={'text-list text-list-right'}>
              <ul>
                <li>I'm passionate about personal growth and pouring into those around me.</li>
                <li>Have questions about what I've done? Interested in becoming a better software developer/engineer? Need someone to do freelance work?</li>
                <li><Link to={'/contact'}><span className={'typography-link typography-link-gray'}>Let's talk!</span></Link></li>
              </ul>
            </div>
            <div className={'text-list text-list-left'}>
              <ul>
                <li>My Christian Faith is foundational to who I am and what I do. I'm not ashamed of that.</li>
                <li>I understand that makes some people uncomfortable, and if that's you, feel free to skip ahead.</li>
                <li>I work hard, do my best to produce excellent results, and treat my colleagues with dignity and respect.</li>
              </ul>
            </div>
          </div>
          <Placard/>
          <div style={{backgroundColor: '#5F6260'}}>
            <CalloutAction linkTo={'/contact'}>
              Let's Go Far
            </CalloutAction>
          </div>
        </LayoutStyles>
      </Layout>
    </div>
  );
}
