import React from "react"
import { Link } from 'gatsby'

const CalloutAction = ({ children, linkTo }) => {
  return (
    <div style={{display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20vh 0',
    }}>
      {
        linkTo
          ?
          <Link to={linkTo}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80vw',
                height: '20vh',
                textAlign: 'center',
                verticalAlign: 'center',
                backgroundColor: '#C4C4C4',
                fontSize: '2rem',
                fontWeight: '120',
                borderRadius: '3px',
                boxShadow: '0 0 12px 10px rgba(0, 0, 0, 0.44)'
              }}
            >
              {children}
            </div>
          </Link>
          : //Default case
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80vw',
              height: '20vh',
              textAlign: 'center',
              verticalAlign: 'center',
              backgroundColor: '#C4C4C4',
              fontSize: '2rem',
              fontWeight: '120',
              borderRadius: '3px',
              boxShadow: '0 0 12px 10px rgba(0, 0, 0, 0.44)'
            }}
          >
            {children}
          </div>
      }
    </div>
  )
}

export default CalloutAction