import React from "react"
import SectionTransition from "../SectionTransition/SectionTransition"

const Placard = () => {
  return (
    <div>
      <SectionTransition style={{position: 'static'}}  from={'#5F6260'} to={'#C4C4C4'}/>
      <div
        style={{
          textAlign: 'center',
          backgroundColor: '#c4c4c4',
          padding: '2vh 4vw',
          fontSize: '1.7rem',
          lineHeight: '1.7',
        }}>
        "If you want to go fast, go alone.<br/>
        If you want to go far, go together."<br/>
        - African Proverb
      </div>
      <SectionTransition style={{position: 'static'}} from={'#C4C4C4'} to={'#5F6260'}/>
    </div>
  )
}

export default Placard