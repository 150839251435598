import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from 'styled-components'

const ProfilePic = ({ children, className }) => (
  <StaticQuery
    query={graphql`
      query {
        profilePic: file(relativePath: { eq: "headshot-smile-white.jpg"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <BackgroundImage
          Tag={'div'}
          className={className}
          fluid={data.profilePic.childImageSharp.fluid}
          backgroundColor={`#BFBAAD`}
          style={{
            margin: '6vh auto 2vh auto',
            backgroundSize: 'cover',
            borderTopRightRadius: '3px',
            borderTopLeftRadius: '3px',
          }}
        >
        </BackgroundImage>
      )
    }}
  />
)

const StyledProfilePic = styled(ProfilePic)`
  @media only screen and (max-width: 425px) {
    height: 80vw;
    width: 80vw;
  }
 
  @media only screen and (min-width: 426px) {
    height: 60vw;
    width: 60vw;
  }
 
  @media only screen and (min-width: 769px) {
    height: 40vw;
    width: 40vw;
  }
`

export default StyledProfilePic